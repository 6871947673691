import axios from "axios";
export function queryPlatProjectOverview(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPlatProjectOverview.html", params)
}


export function queryProjectInfoByBidType(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryProjectInfoByBidType.html", params)
}

// 月度招采统计
export function queryYearProjectInfo(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryYearProjectInfo.html", params)
}

// 平台招标方式占比
export function queryPlatPurchaseMethod(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPlatPurchaseMethod.html", params)
}

// 会员费统计
export function queryPlatMemberFee(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPlatMemberFee.html", params)
}

// 平台服务费统计
export function selectPlatServiceFee(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPlatServiceFee.html", params)
}

// 平台PV/PU情况统计
export function queryPlatData(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/plat/queryPlatData.html", params)
}
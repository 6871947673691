<template>
    <el-form ref="form" :model="form" style="padding:20px;" class="report-procurement">
        <div class="report-row01">
            <div class="report-row01-line1">
                <div style="display:flex; height:(50% + 20px) ;min-height: 190px; margin-bottom: 10px;">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>项目总览</span>
                                <el-date-picker size="mini" style="float:right" v-model="placeholderTime"
                                    type="datetimerange" range-separator="至" start-placeholder="开始日期"
                                    @change="xmzlChange" end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                            <div v-show="!isLoadingzl" class="text item" style="display:flex;flex-wrap: wrap;">
                                <div v-for="(item, index) in project" :key="index" class="report-procurement-pro-card"
                                    :style="{ backgroundColor: colorRgb(item.bcolor) }">
                                    <div class="report-procurement-icon" :style="{ backgroundColor: item.bcolor }"><i
                                            :class="item.icon" /></div>
                                    <div>
                                        <div><span style="font-weight: 600;font-size: 18px;">{{ item.value
}}</span><span style="font-size:12px;">{{ item.nuit }}</span>
                                        </div>
                                        <div>{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <el-skeleton v-show="isLoadingzl" :rows="2" style="height:100%" />
                        </el-card>
                    </div>
                </div>
                <div style="height:calc(50% - 40px) ;min-height: 225px;" class="report-procurement-detail">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <el-tabs v-model="activeName" @tab-click="tabsChangge">
                                    <el-tab-pane label="物资" name="10" />
                                    <el-tab-pane label="工程" name="20" />
                                    <el-tab-pane label="服务" name="30" />
                                </el-tabs>
                            </div>
                            <div v-show="!isLoadingzj" style="display:flex;">
                                <div v-for="(item, index) in detail" :key="index"
                                    class="report-procurement-detail-card">
                                    <div class="report-detail-card-head"
                                        :style="{ backgroundColor: colorRgb(item.bcolor) }">{{ item.name }}</div>
                                    <div class="report-detail-card-body"
                                        :style="{ backgroundColor: colorRgb(item.bcolor) }">
                                        <span style="font-weight: 600;font-size: 20px;">{{ item.value }}</span><span
                                            style="font-size:12px;">{{ item.nuit }}</span>
                                    </div>
                                </div>
                            </div>
                            <el-skeleton v-show="isLoadingzj" :rows="3" style="height:100%" />
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="report-row01-line2" style="display:flex;min-width:900px;width: 50%;">
                <div style="min-width:450px;width: 50%;margin-right:10px;">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>平台月度招采统计</span>
                            <el-date-picker style="float:right" size="mini" v-model="ydzctjValue" type="year"
                                @change="ydzctjChange" placeholder="选择年">
                            </el-date-picker>
                        </div>
                        <div v-show="!isLoadingzc" id="ptydzctjEcharts" style="width: 430px;height:320px"></div>
                        <el-skeleton v-show="isLoadingzc" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
                <div style="min-width:450px;width: 50%;">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>采购物资价格趋势</span>

                        </div>
                        <div v-show="!isLoadingwz" class="text item" style="display:flex;flex-wrap: wrap;">
                            <!-- <div id="cgwzjgqsEcharts" style="width: 430px;height:320px"></div> -->
                            <el-empty :image-size="200" style="width: 430px;height:320px"></el-empty>
                        </div>
                        <el-skeleton v-show="isLoadingwz" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
            </div>
        </div>
        <div class="report-row02">
            <div class="report-row02-line1">
                <div style="min-width:450px;width: 50%;margin-right:10px;">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>平台招标方式占比</span>

                        </div>
                        <div v-show="!isLoadingzb" id="ptzbfszbEcharts" style="width: 430px;height:370px"></div>
                        <el-skeleton v-show="isLoadingzb" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
                <div class="report-row02-line1-2">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>平台PV/PU情况统计</span>
                            <el-date-picker style="float:right" size="mini" v-model="pickerTimeValue" type="daterange"
                                :picker-options="pickerOptions" @change="timeChange" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                            <!-- <el-date-picker style="float:right" v-show="tabPosition==2" size="mini" v-model="value1" type="monthrange"
                                start-placeholder="开始月份" end-placeholder="结束月份">
                            </el-date-picker>
                            <el-date-picker v-show="tabPosition==3" v-model="value1" size="mini" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" format="yyyy">
                            </el-date-picker> -->
                        </div>
                        <div v-show="!isLoadingpv">
                            <div style="width: 430px;height:50px;display: flex;">
                                <el-radio-group size="mini" v-model="tabPosition" style="margin-bottom: 20px;"
                                    @change="groupChange">
                                    <el-radio-button label="day">按日</el-radio-button>
                                    <el-radio-button label="month">按月</el-radio-button>
                                </el-radio-group>

                            </div>
                            <div id="ptpvqktjEcharts" style="width: 430px;height:320px"></div>
                        </div>
                        <!-- <el-empty :image-size="200" style="width: 430px;height:370px"></el-empty> -->
                        <el-skeleton v-show="isLoadingpv" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
            </div>
            <div class="report-row02-line2" style="display:flex;min-width:910px;width: 50%;">
                <div style="min-width:450px;width: calc(50% - 5px);margin-right:10px;">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>平台会员费统计</span>
                            <el-date-picker style="float:right" size="mini" v-model="pthytjValue" type="year"
                                @change="pthytjChange" placeholder="选择年">
                            </el-date-picker>
                        </div>
                        <div v-show="!isLoadinghy" id="pthyftjEcharts" style="width: 430px;height:370px"></div>
                        <el-skeleton v-show="isLoadinghy" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
                <div class="report-row02-line1-2">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>平台服务费统计</span>
                            <el-date-picker style="float:right" size="mini" v-model="ptfwtjValue" type="year"
                                @change="ptfwtjChange" placeholder="选择年">
                            </el-date-picker>
                        </div>
                        <div v-show="!isLoadingfw" id="ptfwftjEcharts" style="width: 430px;height:370px"></div>
                        <el-skeleton v-show="isLoadingfw" :rows="8" style="height:100%;width: 47%;" />
                    </el-card>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { nextTick } from 'vue';
import { queryPlatProjectOverview, queryProjectInfoByBidType, queryYearProjectInfo, queryPlatPurchaseMethod, queryPlatMemberFee, selectPlatServiceFee, queryPlatData } from "@/api/report/platform";
import moment from 'moment';
export default {
  name:'platform',
    data() {
        return {
            tabPosition: 'month',
            isLoadingzl: false,
            isLoadingfw: false,
            isLoadinghy: false,
            isLoadingpv: false,
            isLoadingzb: false,
            isLoadingwz: false,
            isLoadingzc: false,
            ptfwtjValue: "",
            pthytjValue: "",
            ydzctjValue: [],
            placeholderTime: [],
            myChart: null,
            pickerTimeValue: "",
            project: [
                { name: "发布项目", value: 200, nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                { name: "成交项目", value: 160, nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                { name: "成交金额", value: 500, nuit: "万元", icon: 'el-icon-coin', bcolor: '#8dc63f' },
                { name: "用户", value: 700, nuit: "个", icon: 'el-icon-aim', bcolor: '#6739b6' },
                { name: "供应商", value: 100, nuit: "个", icon: 'el-icon-sold-out', bcolor: '#8799a3' },
                { name: "会员", value: 400, nuit: "个", icon: 'el-icon-aim', bcolor: '#1cbbb4' },
            ],
            detail: [
                { name: "总概算", value: 300, nuit: "万元", bcolor: '#E6A23C' },
                { name: "撮合交易额", value: 260, nuit: "万元", bcolor: '#F56C6C' },
                { name: "降成本", value: 40, nuit: "万元", bcolor: '#8dc63f' },
                { name: "进行中", value: 25, nuit: "个", bcolor: '#6739b6' },
                { name: "已完结", value: 120, nuit: "个", bcolor: '#8799a3' },
                { name: "已流标", value: 0, nuit: "个", bcolor: '#1cbbb4' },
                { name: "参与供应商", value: 53, nuit: "个", bcolor: '#9c26b0' },
            ],
            activeName: '10',
            form: {

            },
            pickerOptions: {
                disabledDate(time) {
                    // Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
                    // 禁用当前日期及以后的日期
                    return time.getTime() < moment("2022-01-29", 'YYYY-MM-DD');
                    // 禁用当前日期之前的日期
                    // return time.getTime() > Date.now() - 8.64e7;
                }
            }
        }
    },
    created() {
        this.getYearProjectInfo()
        this.getProjectOverviewData()
        this.getProjectInfoByBidTypeData(10);
        // 平台招标方式占比
        this.getPlatPurchaseMethodData();
        // 平台会员费统计
        this.getPlatMemberFeeData();
        // 平台服务费统计
        this.getPlatServiceFee();
        // 平台PV/PU情况统计
        this.pickerTimeValue = [new Date(moment().startOf('year')), new Date()];
        this.setPVEcharts([new Date(moment().startOf('year')), new Date()]);

    },
    methods: {
        // 平台服务费统计
        getPlatServiceFee(year) {
            let that = this;
            let query = { agentId: this.$route.query.agentId };
            if (year) {
                query.year = year;
            }
            this.isLoadingfw = true;
            selectPlatServiceFee(this.member, query).then(res => {
                this.isLoadingfw = false;
                if (res.status == 200) {
                    let data = res.data;
                    let data1 = [];
                    let data2 = [];
                    let xdata = [];
                    if (year == undefined || year == moment().year().toString()) {
                        let month = moment().month() + 1;
                        for (let index = 0; index < month; index++) {
                            xdata.push(month.toString())
                        }

                    } else {
                        xdata = [
                            '1 ',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                            '11',
                            '12'
                        ]
                    }
                    for (const key in data.serviceFeeTotal) {
                        data1.push(data.serviceFeeTotal[key]);
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }
                    for (const key in data.serviceFee) {
                        data2.push(data.serviceFee[key]);
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }
                    var myChart = this.$echarts.init(document.getElementById('ptfwftjEcharts'));
                    let options = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },

                        legend: {
                            data: ['服务费', '累计服务费'],
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xdata,
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: "#303133",
                                    },
                                },
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '服务费',
                                // min: 0,
                                // max: 3500,
                                // interval: 500,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: '累计服务费',
                                // min: 0,
                                // max: 35000,
                                // interval: 5000,
                                axisLabel: {
                                    formatter: '{value}'
                                },
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '服务费',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                barMaxWidth:100,
                                data: data2
                            },
                            {
                                name: '累计服务费',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data1
                            }
                        ]
                    }
                    myChart.setOption(options);
                }
            })
        },
        // 平台会员费统计
        getPlatMemberFeeData(year) {
            let that = this;
            let query = { agentId: this.$route.query.agentId };
            if (year) {
                query.year = year;
            }
            this.isLoadinghy = true;
            queryPlatMemberFee(this.member, query).then(res => {
                this.isLoadinghy = false;
                if (res.status == 200) {
                    let data = res.data;
                    let data1 = [];
                    let data2 = [];
                    let xdata = [];
                    if (year == undefined || year == moment().year().toString()) {
                        let month = moment().month() + 1;
                        for (let index = 0; index < month; index++) {
                            xdata.push(month.toString())
                        }

                    } else {
                        xdata = [
                            '1 ',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                            '11',
                            '12'
                        ]
                    }
                    for (const key in data.memberFeeTotal) {
                        data1.push(data.memberFeeTotal[key]);
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }
                    for (const key in data.memberFee) {
                        data2.push(data.memberFee[key]);
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }
                    var myChart = this.$echarts.init(document.getElementById('pthyftjEcharts'));
                    let options = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },

                        legend: {
                            data: ['会员费', '累计会员费'],
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xdata,
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: "#303133",
                                    },
                                },
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '会员费',
                                // min: 0,
                                // max: 3500,
                                // interval: 500,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: '累计会员费',
                                // min: 0,
                                // max: 35000,
                                // interval: 5000,
                                axisLabel: {
                                    formatter: '{value}'
                                },
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '会员费',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                barMaxWidth:100,
                                data: data2
                            },
                            {
                                name: '累计会员费',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data1
                            }
                        ]
                    }
                    myChart.setOption(options);
                }
            })
        },
        // 平台招标方式占比
        getPlatPurchaseMethodData() {
            let query = { agentId: this.$route.query.agentId };
            this.isLoadingzb = true;
            queryPlatPurchaseMethod(this.member, query).then(res => {
                this.isLoadingzb = false;
                if (res.status == 200) {
                    let temdata = []
                    for (const key in res.data.purchaseMode) {
                        temdata.push({ value: res.data.purchaseMode[key], name: key })
                    }
                    var myChart = this.$echarts.init(document.getElementById('ptzbfszbEcharts'));
                    let options = {
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)'
                        },
                        legend: {
                            orient: 'vertical',
                            x: 'lect',
                            y: 'bottom',
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        series: [
                            {
                                name: '平台招标方式占比',
                                type: 'pie',
                                radius: '50%',
                                data: temdata
                            }
                        ]
                    }
                    myChart.setOption(options);
                }
            })
        },
        // 平台会员费切换
        pthytjChange(e) {
            this.getPlatMemberFeeData(moment(e.getTime()).format("YYYY"))
        },
        // 平台服务费切换
        ptfwtjChange(e) {
            this.getPlatServiceFee(moment(e.getTime()).format("YYYY"))
        },
        ydzctjChange(e) {
            this.getYearProjectInfo(moment(e.getTime()).format("YYYY"))
        },
        xmzlChange(e) {
            this.getProjectOverviewData(e);
        },
        getProjectOverviewData(time) {
            let query = { agentId: this.$route.query.agentId };
            if (time && time.length == 2) {
                query.startTime = moment(time[0].getTime()).format("YYYY-MM-DD HH:mm:ss");
                query.endTime = moment(time[1].getTime()).format("YYYY-MM-DD HH:mm:ss");
            }
            this.isLoadingzl = true;
            queryPlatProjectOverview(this.member, query).then(res => {
                this.isLoadingzl = false;
                if (res.status == 200) {
                    let temProject = [
                        { name: "累计发布项目", value: this.setNumber(res.data.publishProject), nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                        { name: "累计成交项目", value: this.setNumber(res.data.finishProject), nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                        { name: "累计成交金额", value: this.setNumber(res.data.totalAwardPrice), nuit: this.setNumber(res.data.totalAwardPrice, true), icon: 'el-icon-coin', bcolor: '#8dc63f' },
                        { name: "累计用户", value: this.setNumber(res.data.userAmount), nuit: "个", icon: 'el-icon-aim', bcolor: '#6739b6' },
                        { name: "累计供应商", value: this.setNumber(res.data.supplierAmount), nuit: "个", icon: 'el-icon-sold-out', bcolor: '#8799a3' },
                        { name: "累计会员", value: this.setNumber(res.data.memberAmount), nuit: "个", icon: 'el-icon-aim', bcolor: '#1cbbb4' },
                    ]
                    this.project = temProject;
                }
            })
        },
        // 平台月度招采统计
        getYearProjectInfo(year) {
            let query = { agentId: this.$route.query.agentId };
            if (year) {
                query.year = year;
            }
            let that = this;
            this.isLoadingzc = true;
            queryYearProjectInfo(this.member, query).then(res => {
                this.isLoadingzc = false;
                if (res.status == 200) {
                    // 成交额
                    let data1 = []
                    // 招采项目数
                    let data2 = []
                    let xdata = [];
                    if (year == undefined || year == moment().year().toString()) {
                        let month = moment().month() + 1;
                        for (let index = 0; index < month; index++) {
                            xdata.push(month.toString())
                        }

                    } else {
                        xdata = [
                            '1 ',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                            '11',
                            '12'
                        ]
                    }

                    for (const key in res.data.yearAward) {
                        const element = res.data.yearAward[key];
                        data1.push(element)
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }

                    for (const key in res.data.yearCount) {
                        const element = res.data.yearCount[key];
                        data2.push(element)
                        if ((year == undefined || year == moment().year().toString()) && key > moment().month()) break;
                    }

                    // 月度招采统计
                    var myChart = this.$echarts.init(document.getElementById('ptydzctjEcharts'));
                    // 指定图表的配置项和数据
                    var options = {
                        legend: {
                            data: ['采购项目数', '撮合交易额'],
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        grid: {
                            right: '17%',
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xdata,
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: '#303133'
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 5000,
                                // interval: 500,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 50,
                                // interval: 5,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '采购项目数',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                barMaxWidth:100,
                                data: data2
                            },
                            {
                                name: '撮合交易额',
                                type: 'line',
                                // symbol: 'none',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data1
                            }
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(options);
                }
            })
        },
        tabsChangge(e) {
            this.getProjectInfoByBidTypeData(e.name);
        },
        getProjectInfoByBidTypeData(bidType) {
            let query = { bidType: bidType, agentId: this.$route.query.agentId };
            this.isLoadingzj = true;
            queryProjectInfoByBidType(this.member, query).then(res => {
                this.isLoadingzj = false;
                if (res.status == 200) {
                    let temdetail = [
                        { name: "预算金额", value: this.setNumber(res.data.finishBudgetAmountPlat), nuit: this.setNumber(res.data.finishBudgetAmountPlat, true), bcolor: '#E6A23C' },
                        { name: "成交金额", value: this.setNumber(res.data.totalAwardPrice), nuit: this.setNumber(res.data.totalAwardPrice, true), bcolor: '#F56C6C' },
                        { name: "降成本", value: this.setNumber(res.data.lower), nuit: this.setNumber(res.data.lower, true), bcolor: '#8dc63f' },
                        { name: "进行中", value: this.setNumber(res.data.progress), nuit: "个", bcolor: '#6739b6' },
                        { name: "已完结", value: this.setNumber(res.data.finished), nuit: "个", bcolor: '#8799a3' },
                        { name: "已流标", value: this.setNumber(res.data.projectEnd), nuit: "个", bcolor: '#1cbbb4' },
                        { name: "参与供应商", value: this.setNumber(res.data.sendSupplier), nuit: "个", bcolor: '#9c26b0' },
                    ]
                    this.detail = temdetail;
                }
            }
            )
        },
        setAxisLabel(value) {
            if (Math.abs(value) < 10000) {
                return value;
            } else if (Math.abs(value) < 100000000) {
                return (value / 10000).toFixed(1) + "万";
            } else if (Math.abs(value) < 1000000000000) {
                return (value / 100000000).toFixed(1) + "亿";
            } else {
                return (value / 1000000000000).toFixed(1) + "万亿";
            }
        },
        setNumber(value, isUnit = false) {
            if (isUnit) {
                if (Math.abs(value) < 10000) {
                    return "元";
                } else if (Math.abs(value) < 100000000) {
                    return "万元";
                } else if (Math.abs(value) < 1000000000000) {
                    return "亿元";
                } else {
                    return "万亿";
                }
            } else {
                if (Math.abs(value) < 10000) {
                    return value;
                } else if (Math.abs(value) < 100000000) {
                    return (value / 10000).toFixed(0);
                } else if (Math.abs(value) < 1000000000000) {
                    return (value / 100000000).toFixed(0);
                } else {
                    return (value / 1000000000000).toFixed(0);
                }
            }
        },
        groupChange(e) {
            this.setPVEcharts(this.pickerTimeValue, e);
        },
        timeChange(time) {
            this.setPVEcharts(time, this.tabPosition);
        },
        async setPVEcharts(time, type = 'month') {
            let that = this;
            this.isLoadingpv = true;
            // 平台PV/PU情况统计
            let query = { agentId: this.$route.query.agentId };
            if (time && time.length == 2) {
                query.startTime = moment(time[0].getTime()).format("YYYYMMDD");
                query.endTime = moment(time[1].getTime()).format("YYYYMMDD");
            } else {
                query.startTime = moment(new Date(moment().startOf('year'))).format("YYYYMMDD");
                query.endTime = moment(new Date().getTime()).format("YYYYMMDD");
            }
            if (type) {
                query.type = type;
            }
            let itemx = [];
            let data1 = [];//PU
            let data2 = [];//PV
            await queryPlatData(this.member, query).then(res => {
                if (res.status == 200) {
                    let items = res.data.body.data[0].result.items;
                    for (const iterator of items[1]) {
                        // day/hour/week/month
                        if (type == 'day') itemx = items[0];
                        if (type == 'month') {
                            let temItemx = []
                            for (const itx of items[0]) {
                                temItemx.push(itx[0].slice(5, 7))
                            }
                            itemx = temItemx;
                        }
                        data1.push(iterator[2])
                        data2.push(iterator[1])
                    }
                }
            })
            if (time && time.length == 2) {
                query.startTime = (Number(query.startTime) - 10000).toString();
                query.endTime = (Number(query.endTime) - 10000).toString();
            }
            let olddata1 = [];//PU
            let olddata2 = [];//PV
            // 去年数据
            await queryPlatData(this.member, query).then(res => {
                if (res.status == 200) {
                    let items = res.data.body.data[0].result.items;
                    for (const iterator of items[1]) {
                        olddata1.push(iterator[2])
                        olddata2.push(iterator[1])
                    }

                }
            })
            await new Promise(() => {
                var myChart = this.$echarts.init(document.getElementById('ptpvqktjEcharts'));
                itemx = itemx.reverse();
                data1 = data1.reverse();//PU
                data2 = data2.reverse();//PV
                olddata1 = olddata1.reverse();//PU
                olddata2 = olddata2.reverse();//PV
                let options = {
                    legend: {
                        data: ['PV', 'PU'],
                        textStyle: {
                            color: '#303133'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        formatter: function (params) {
                            let str = '';
                            params.forEach((item, idx) => {
                                str += `${item.marker} ${item.data}<br/>`;
                                switch (idx) {
                                    case 0:
                                        // 今年第一月
                                        if (item.dataIndex == 0) {
                                            // 和去年最后一月相比
                                            if (typeof item.data == "string") {
                                                str += `同比:<span style="color:#67C23A;">0%</span><br/>`;
                                            } else if (item.data - olddata1[olddata1.length - 1] < 0) {
                                                str += `同比:<span style="color:#F56C6C;">-${((olddata1[olddata1.length - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else if (item.data - olddata1[olddata1.length - 1] == 0) {
                                                str += '同比:0%<br/>';
                                            } else if (typeof olddata1[olddata1.length - 1] == "string") {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - olddata1[olddata1.length - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            }
                                        } else {
                                            // 和上一月相比
                                            if (typeof item.data == "string") {
                                                str += `同比:<span style="color:#67C23A;">0%</span><br/>`;
                                            } else if (item.data - data1[item.dataIndex - 1] < 0) {
                                                str += `同比:<span style="color:#F56C6C;">-${((data1[item.dataIndex - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else if (item.data - data1[item.dataIndex - 1] == 0) {
                                                str += '同比:0%<br/>';
                                            } else if (typeof data1[item.dataIndex - 1] == "string") {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - data1[item.dataIndex - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            }
                                        }
                                        // 和去年相比
                                        if (typeof item.data == "string") {
                                            str += `环比:<span style="color:#67C23A;">0%</span><br/>`;
                                        } else if (item.data - olddata1[item.dataIndex] < 0) {
                                            str += `环比:<span style="color:#F56C6C;">-${((olddata1[item.dataIndex] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - olddata1[item.dataIndex] == 0) {
                                            str += '环比:0%<br/>';
                                        } else if (typeof olddata1[item.dataIndex] == "string") {
                                            str += `环比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else {
                                            str += `环比:<span style="color:#67C23A;">+${((item.data - olddata1[item.dataIndex]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                        break;
                                    case 1:
                                        // 今年第一月
                                        if (item.dataIndex == 0) {
                                            // 和去年最后一月相比
                                            if (typeof item.data == "string") {
                                                str += `同比:<span style="color:#67C23A;">0%</span><br/>`;
                                            } else if (item.data - olddata2[olddata2.length - 1] < 0) {
                                                str += `同比:<span style="color:#F56C6C;">-${((olddata2[olddata2.length - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else if (item.data - olddata2[olddata2.length - 1] == 0) {
                                                str += '同比:0%<br/>';
                                            } else if (typeof olddata2[olddata2.length - 1] == "string") {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - olddata2[olddata2.length - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            }
                                        } else {
                                            // 和上一月相比
                                            if (typeof item.data == "string") {
                                                str += `同比:<span style="color:#67C23A;">0%</span><br/>`;
                                            } else if (item.data - data2[item.dataIndex - 1] < 0) {
                                                str += `同比:<span style="color:#F56C6C;">-${((data2[item.dataIndex - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else if (item.data - data2[item.dataIndex - 1] == 0) {
                                                str += '同比:0%<br/>';
                                            } else if (typeof data2[item.dataIndex - 1] == "string") {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            } else {
                                                str += `同比:<span style="color:#67C23A;">+${((item.data - data2[item.dataIndex - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                            }
                                        }
                                        // 和去年相比
                                        if (typeof item.data == "string") {
                                            str += `环比:<span style="color:#67C23A;">0%</span><br/>`;
                                        } else if (item.data - olddata2[item.dataIndex] < 0) {
                                            str += `环比:<span style="color:#F56C6C;">-${((olddata2[item.dataIndex] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - olddata2[item.dataIndex] == 0) {
                                            str += '环比:0%<br/>';
                                        } else if (typeof olddata2[item.dataIndex] == "string") {
                                            str += `环比:<span style="color:#67C23A;">+${((item.data - 0) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else {
                                            str += `环比:<span style="color:#67C23A;">+${((item.data - olddata2[item.dataIndex]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                        break;
                                    default:
                                        str += '暂无';
                                }
                                str += idx === params.length - 1 ? '' : '<br/>';
                            });
                            return str;
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: itemx,
                        axisLabel: {
                            textStyle: {
                                color: "#303133",
                            },
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: "#303133",
                            },
                            formatter: function (value, index) {
                                return that.setAxisLabel(value);
                            }
                        }
                    },
                    series: [
                        {
                            data: data1,
                            type: 'line',
                            name: 'PU',
                            // symbol: 'none'
                        },
                        {
                            data: data2,
                            type: 'line',
                            name: 'PV',
                            // symbol: 'none'
                        }
                    ]
                }
                myChart.setOption(options);
                this.isLoadingpv = false;
            })

        },
        colorRgb(colorStr) {
            //十六进制颜色值的正则表达式
            var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
            var sColor = colorStr.toLowerCase();
            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    var sColorNew = "#";
                    for (var i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                    }
                    sColor = sColorNew;
                }
                //处理六位的颜色值f
                var sColorChange = [];
                for (var i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
                }
                return "rgb(" + sColorChange.join(" ") + ' / 10%' + ")";
            } else {
                return sColor;
            }
        }
    }
}
</script>

<style scoped>
.el-card {
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .report-row01 {
        height: 50%;
        min-height: 420px;
        margin-bottom: 10px;
    }

    .report-row02 {
        height: 50%;
        min-height: 285px;
    }

    .report-row01-line1 {
        margin: 0 auto 10px auto;
        min-width: 910px;
        width: 50%;
    }

    .report-row02-line2,
    .report-row01-line2 {
        margin: 0 auto;
        min-width: 910px !important;
    }

    .report-row02-line1 {
        display: flex;
        min-width: 910px;
        width: 50%;
        margin: 0 auto 10px auto;
    }

    .report-row02-line1-2 {
        min-width: 450px;
        width: 50%;
    }

}

@media (min-width: 1200px) {
    .report-row01 {
        height: 50%;
        min-height: 420px;
        margin-bottom: 10px;
        display: flex;
    }

    .report-row01-line2 {
        /* margin-right: 10px; */
    }

    .report-row02 {
        height: 50%;
        min-height: 285px;
        display: flex;
    }

    .report-row01-line1 {
        margin-right: 10px;
        min-width: 900px;
        width: calc(50% - 10px);
    }

    .report-row02-line1 {
        display: flex;
        min-width: 910px;
        width: calc(50% - 10px);
        margin-right: 10px;
    }

    .report-row02-line1-2 {
        min-width: 440px;
        width: calc(50% - 5px);
    }
}



.procurement-table-body01>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.procurement-table-body02>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.report-procurement>>>.el-skeleton {
    background-color: #fff;
    padding: 10px;
    height: 100%;
}

.report-procurement>>>.el-card__body,
.el-main {
    width: 925px;
}

.report-procurement-detail>>>.el-range-editor--mini .el-range-separator {
    z-index: 3;
}

.report-procurement-detail>>>.el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon,
.report-procurement-detail>>>.el-range-editor--mini .el-range__close-icon,
.el-range-editor--mini .el-range__icon {
    display: none;
}

.report-procurement-detail>>>.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
    width: 280px !important;
}

.report-procurement-detail>>>.el-tabs__header {
    margin: 0;
}

.report-procurement-detail>>>.el-tabs__nav-wrap::after {
    display: none;
}

.report-procurement-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    font-size: 25px;
    color: #fff;
    margin-right: 10px;
}

.report-procurement-table>>>.el-table--fit {
    height: 356px !important;
}

.report-procurement-pro-card {
    display: flex;
    margin-bottom: 5px;
    margin-right: 5px;
    /* padding: 5px 0 5px 5px; */
    align-items: center;
    justify-content: center;
    width: 16%;
    height: 80px;
}

.report-procurement-detail-card {
    /* background-color: #ffe0ff; */
    height: 100px;
    width: 110px;
    margin: 0 10px;
    border-radius: 5px;
}

.report-detail-card-head::after {
    content: '';
    width: 80px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
}

.report-detail-card-head {
    background-color: rgb(255 224 255 / 50%);
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    position: relative;
}

.report-detail-card-body {
    background-color: rgb(255 224 255 / 50%);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.report-procurement>>>.el-col-12,
.report-procurement>>>.grid-content,
.report-procurement>>>.el-card {
    height: 100% !important;
    width: 100% !important;
}
</style>